* {
    box-sizing: border-box;
    font-family: Inter, sans-serif;
    
}

body {
    background-color:  #23252C !important;
    /* overflow: hidden; */
}

.main {
    width: 317px;
    margin: auto;
    display: flex;
    justify-content: center;
    padding: 44px 117px;
}

.main-card {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px 0px;
    border-radius: 10px;
}

.profilImage {
    border-radius: 10px 10px 0px 0px;
    width: 317px;
}

.content {
    background-color: #1A1B21;
    padding-bottom: 20px;
}

.info{
    padding-top: 20px;
    padding-bottom: 20px;
}

.infoName {
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    color: white;
}

.infoRole {
    font-size: 12.8px;
    font-weight: 400px;
    color: #F3BF99;
    text-align: center;
    padding-top: 6px;
}

.infoWebsite {
    font-size: 10.24px;
    font-weight: 400px;
    text-align: center;
    padding-top: 8px;
    color: white;
}

.info-socmed {
    margin: 10px 32px;   
}

.emailBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 13px 9px 11px;
    
    width: 115px;
    border-radius: 6px;
    /* padding: 9px 13px 9px 11px !important; */
    border: 1px solid #D1D5DB !important;
    background-color: white !important;
    margin-right: 17px !important;
}

a.emailBtn:hover {
    background-color: #374151 !important;
    color: white;
    border-color: #374151
}

.linkedBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 13px 9px 11px;
    
    
    width: 115px;
    border-radius: 6px;
    background-color: #5093E2 !important;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    color: white !important;
}

a.linkedBtn:hover {
    background-color: white !important;
    color: #5093E2 !important;
    border-color: #5093E2;
}

.info-about {
    width: 247px;
    margin: 0px 32px;
    padding-bottom: 20px;
}

.about-title {
    font-size: 16px;
    font-weight: 700;
    color: white;
}

.about-content {
    font-size: 10px;
    font-weight: 400;
    color: white;
    margin-top: 5px;
}

.info-interest {
    width: 247px;
    margin: 0px 32px;
    padding-bottom: 20px;
}

.interest-title {
    font-size: 16px;
    font-weight: 700;
    color: white;
}

.interest-content {
    font-size: 10px;
    font-weight: 400;
    color: white;
    margin-top: 5px;
}

.footer{
    /* width: 317px; */
    height: 64px;
    background: #161619;
    border-radius: 0px 0px 10px 10px;
}

.footer-content {
    display: flex;
    justify-content: center;
    padding: 20px 32px;
}

.footer-icon a{
    color: #161619;
    background-color: #918E9B;
    vertical-align: middle;
    justify-content: center;
    padding: 1px 5px 5px;
    margin-left: 12px;
    margin-right: 12px;
    border-radius: 3px;
}

.footer-icon a:hover{
    color: white;
    background-color: 161619;
}





